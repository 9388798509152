import React from 'react';
import Spinner from '../Spinner/Spinner';

const LoadingHeader = ({ count, backgroundColor }) => {

  const loadingItems = Array.from({ length: count }, (_, index) => (
    <div key={index} className="px-4 mt-1">
      <Spinner backgroundColor={backgroundColor} />
    </div>
  ));

  return <div className="d-flex">{loadingItems}</div>;
};

export default LoadingHeader;
