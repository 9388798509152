import { HttpClient } from '../../Utils/ApiUtilies/Fetch/Fetch';
import { GET_COUNTRIES_MULTILANGUAJE } from '../Paths';

const ACTIONS_ID = 'COUNTRIES_DATA';
const actionType = {
  INTERESET_REGISTER_COUNTRIES_CODE: `${ACTIONS_ID}_INTERESET_REGISTER_COUNTRIES_CODE`,
  INTERESET_REGISTER_COUNTRIES: `${ACTIONS_ID}_INTERESET_REGISTER_COUNTRIES`
};

const setInterestRegisterCountriesCode = (countriesCode) => ({
  type: actionType.INTERESET_REGISTER_COUNTRIES_CODE,
  payload: {
    countriesCode
  }
});
const setInterestRegisterCountries = (countries) => ({
  type: actionType.INTERESET_REGISTER_COUNTRIES,
  payload: {
    countries
  }
});

const getDataInterestRegisterForm = () => (dispatch, getStore) => {
  Promise.all(HttpClient.GET(GET_COUNTRIES_MULTILANGUAJE))
    .then((countriesResponse) => {
      const sortedData = countriesResponse.sort((a, b) => a.value.localeCompare(b.value));
      dispatch(setInterestRegisterCountriesCode([]));
      dispatch(setInterestRegisterCountries(sortedData || []));
    })
    .catch((error) => {
      console.debug('error', error);
      dispatch(setInterestRegisterCountriesCode([]));
      dispatch(setInterestRegisterCountries([]));
    });
};

export const actions = {
  getDataInterestRegisterForm
};

export const reducers = {
  [actionType.INTERESET_REGISTER_COUNTRIES_CODE]: (state, { countriesCode }) => {
    return {
      ...state,
      interestRegisterFormData: { ...state.interestRegisterFormData, countriesCode }
    };
  },
  [actionType.INTERESET_REGISTER_COUNTRIES]: (state, { countries }) => {
    return {
      ...state,
      interestRegisterFormData: { ...state.interestRegisterFormData, countries }
    };
  }
};

export const initialState = {
  interestRegisterFormData: {
    defaultCountryCode: {
      alpha2Code: 'CO',
      callingCode: '57',
      flag: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg',
      name: 'Colombia'
    },
    countriesCode: [],
    countries: []
  }
};
