import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HttpClient } from '../../../Domain/Utils/ApiUtilies/Fetch/Fetch';
import {
  getParametersAndDuplicateListAsObject,
  getParametersAsObject
} from '../../../Domain/Utils/ApiUtilies/Parameters/Parameters';
import { TYPE_FETCH_PARAMETERS } from '../../../Domain/Settings/Constants/Constants';
import { getUrl } from '../../../Domain/Utils/ApiUtilies/Fetch/GetTypeFethc';

//Funtion for get parameters by component with multilanguage such as multilanguiage, content multilanguage
export const useGetComponentParameters = (components, typeFetch, main_parameter_code = false) => {
  const [parameters, setParameters] = useState({});
  const language = useSelector((store) => store.languageTraslate.language);

  useEffect(() => {
    if (!language?.value) {
      return;
    }
    HttpClient.GET(getUrl(typeFetch), {
      components,
      language: language?.value,
      main_parameter_code
    }).then((response) => {
      const params = getParametersAndDuplicateListAsObject(response.data);
      if (main_parameter_code || typeFetch === TYPE_FETCH_PARAMETERS.CONTENT_MULTILANGUAGE) {
        const array = Object.entries(params).map(([key, value]) => ({
          id: key,
          ...value
        }));
        setParameters(array || []);
        return;
      }
      setParameters(params);
    });
  }, [language, main_parameter_code]);

  return parameters;
};

//Funtion for get parameters by component with main_parameter and multilanguage
export const useGetMainComponentParameters = (components, typeFetch, main_parameter_code) => {
  const [parameters, setParameters] = useState({});
  const language = useSelector((store) => store.languageTraslate.language);

  useEffect(() => {
    if (!language?.value || !main_parameter_code) {
      return;
    }
    HttpClient.GET(getUrl(typeFetch), {
      components,
      language: language?.value,
      main_parameter_code
    }).then((response) => {
      const params = getParametersAndDuplicateListAsObject(response.data);
      if (main_parameter_code) {
        const array = Object.entries(params).map(([key, value]) => ({
          id: key,
          ...value
        }));
        setParameters(array || []);
        return;
      }
      setParameters(params);
    });
  }, [language, main_parameter_code]);

  return parameters;
};

export const useGetComponentParametersSingle = (components, typeFetch, main_parameter_code) => {
  const [parameters, setParameters] = useState({});

  useEffect(() => {
    const requestBody = { components };
    if (
      typeFetch === TYPE_FETCH_PARAMETERS.MAIN_DETAIL ||
      typeFetch === TYPE_FETCH_PARAMETERS.MAIN_LINK
    ) {
      if (main_parameter_code !== undefined) {
        requestBody.main_parameter_code = main_parameter_code;
      } else return;
    }

    try {
      HttpClient.GET(getUrl(typeFetch), requestBody).then((response) => {
        if (response?.data?.length > 0) {
          if (
            typeFetch === TYPE_FETCH_PARAMETERS.MAIN_DETAIL ||
            typeFetch === TYPE_FETCH_PARAMETERS.MAIN_LINK
          ) {
            setParameters(response?.data);
          } else {
            const params = getParametersAsObject(response.data);
            setParameters(params);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [
    typeFetch === TYPE_FETCH_PARAMETERS.MAIN_DETAIL || typeFetch === TYPE_FETCH_PARAMETERS.MAIN_LINK
      ? main_parameter_code
      : null,
    typeFetch
  ]);

  return parameters;
};
