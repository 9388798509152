import { FRONTEND_URL, FRONTENDLMS_URL, FRONTMICROSITE } from '../../Redux/Paths';

export const getParamFromUrl = (id) => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  return queryParams.get(id);
};

const redirect = (url) => {
  window.location.href = url;
};

const redirectBlank = (url) => {
  window.open(`${url}`, '_blank');
};

export const handleNavigation = (url, language, baseUrl) => {
  if (!url) return;
  let cleanUrl = url.replace('/out', '');

  if (cleanUrl.includes('{language}')) {
    cleanUrl = cleanUrl.replace('{language}', language?.description?.toLowerCase());
  }

  if (cleanUrl.includes('{redirect')) {
    if (cleanUrl.includes('{redirect_lms}')) {
      baseUrl = FRONTENDLMS_URL;
      cleanUrl = cleanUrl.replace('{redirect_lms}', baseUrl);
    } else if (cleanUrl.includes('{redirect_payment}')) {
      baseUrl = FRONTMICROSITE;
      cleanUrl = cleanUrl.replace('{redirect_payment}', baseUrl);
    } else {
      baseUrl = FRONTEND_URL;
      cleanUrl = cleanUrl.replace('{redirect}', baseUrl);
    }

    redirectBlank(cleanUrl);
  } else if (cleanUrl.includes('/out')) {
    redirectBlank(cleanUrl);
  } else {
    redirect(cleanUrl);
  }
};
