import { useMemo } from 'react';

const LANGUAGES = {
  STANDARD: 1,
  SPANISH: 2,
  ENGLISH: 3
};

export const LENGUAGES_LIST = {
  es: LANGUAGES.SPANISH,
  en: LANGUAGES.ENGLISH
};

export const searchParameter = (parameters = [], target = '', key = '') => {
  return parameters.find(({ code }) => code === target)[key];
};

export const getParametersAsObject = (parameterList = []) => {
  const parametersAsObject = {};
  for (const parameter of parameterList) {
    parametersAsObject[parameter.code] = parameter;
  }
  return parametersAsObject;
};

/*
 * function to get the parameters as the above function
 * but if there are many parameters with the same code then
 * return them grouped
 */
export const getParametersAndDuplicateListAsObject = (parameterList = []) => {
  const resultObject = {};
  for (const parameter of parameterList) {
    if (resultObject[parameter.code]) {
      if (Array.isArray(resultObject[parameter.code])) {
        resultObject[parameter.code] = [...resultObject[parameter.code], parameter];
      } else {
        resultObject[parameter.code] = [resultObject[parameter.code], parameter];
      }
    } else {
      resultObject[parameter.code] = parameter;
    }
  }
  return resultObject;
};

export default function combineObjects(...objects) {
  return objects.reduce((acc, obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Si la clave ya existe, combina las propiedades y evita duplicados
        acc[key] = acc[key]
          ? mergeUnique(acc[key], obj[key]) // Combinar propiedades sin duplicados
          : { ...obj[key] }; // Si no existe, agregarlo directamente
      }
    }
    return acc;
  }, {});
}

// Función auxiliar para combinar valores únicos
function mergeUnique(target, source) {
  const merged = { ...target };

  for (const prop in source) {
    if (source.hasOwnProperty(prop)) {
      if (Array.isArray(source[prop]) && Array.isArray(merged[prop])) {
        // Combinar arrays y evitar duplicados
        merged[prop] = Array.from(new Set([...merged[prop], ...source[prop]]));
      } else if (typeof source[prop] === 'object' && typeof merged[prop] === 'object') {
        // Combinar objetos recursivamente
        merged[prop] = mergeUnique(merged[prop], source[prop]);
      } else {
        // Reemplazar valor directamente
        merged[prop] = source[prop];
      }
    }
  }

  return merged;
}

export function useCombinedObjects(...objects) {
  return useMemo(() => {
    return combineObjects(...objects);
  }, [...objects]);
}
