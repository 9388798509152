import {
  API_MULTILANGUAGE_PARAMETERS,
  API_MAIN_PARAMETER_MULTILANGUAGE,
  API_DETAIL_PARAMETERS,
  API_WEBLINK_PARAMETERS,
  API_STANDARD_PARAMETERS,
  API_MAIN_DETAIL_PARAMETER,
  API_MAIN_WEBLINK_PARAMETER,
  API_CONTENT_MULTILANGUAGE
} from '../../../Redux/Paths';
import { TYPE_FETCH_PARAMETERS } from '../../../Settings/Constants/Constants';

export const getUrl = (typeFetch) => {
  switch (typeFetch) {
    case TYPE_FETCH_PARAMETERS.MULTILANGUAGE:
      return API_MULTILANGUAGE_PARAMETERS;
    case TYPE_FETCH_PARAMETERS.MAIN_MULTILANGUAGE:
      return API_MAIN_PARAMETER_MULTILANGUAGE;
    case TYPE_FETCH_PARAMETERS.DETAIL:
      return API_DETAIL_PARAMETERS;
    case TYPE_FETCH_PARAMETERS.LINK:
      return API_WEBLINK_PARAMETERS;
    case TYPE_FETCH_PARAMETERS.STANDARD:
      return API_STANDARD_PARAMETERS;
    case TYPE_FETCH_PARAMETERS.MAIN_DETAIL:
      return API_MAIN_DETAIL_PARAMETER;
    case TYPE_FETCH_PARAMETERS.MAIN_LINK:
      return API_MAIN_WEBLINK_PARAMETER;
    case TYPE_FETCH_PARAMETERS.CONTENT_MULTILANGUAGE:
      return API_CONTENT_MULTILANGUAGE;
    default:
      return API_MULTILANGUAGE_PARAMETERS;
  }
};
