import { useState, useEffect, useRef } from 'react';
import { HttpClient } from '../../../Domain/Utils/ApiUtilies/Fetch/Fetch';
import { API_PARAMETER_NAVBAR, API_STRATEGIC_LINE } from '../../../Domain/Redux/Paths';
import { COMPONENTS } from '../../../Domain/Settings/Constants/ComponentsParameter/ComponentsParameter';

const useNavBarMobile = (language, parameters, handleNavigation, isMenuCollapsable) => {
  const lastPayloadRef = useRef({
    payloadMain: {},
    payloadAux: {}
  });

  const [namesStrategicLine, setStrategicsLines] = useState([]);
  const [mainNavBarMobile, setMainNavBar] = useState([]);
  const [auxNavBarMobile, setAuxNavBar] = useState([]);

  useEffect(() => {
    if (isMenuCollapsable) {
      if (!language?.id || !parameters?.NAVBAR_MAIN) return;
      fetchMainNavBar();

      if (!language?.id || namesStrategicLine.length === 0 || !parameters?.NAVBAR_AUX) return;
      fetchAuxNavBar();
    }
  }, [language, namesStrategicLine, parameters, isMenuCollapsable]);

  // Fetch Aux NavBar
  const fetchAuxNavBar = async () => {
    // if (auxNavBarMobile.length > 0) return;
    const payload = {
      language: language.id,
      component: COMPONENTS?.NAVIGATION_BAR,
      main_parameter_code: parameters?.NAVBAR_AUX_MOBILE?.code
    };

    if (
      JSON.stringify(lastPayloadRef.current.payloadMain) === JSON.stringify(payload) ||
      JSON.stringify(lastPayloadRef.current.strategicLine) === JSON.stringify(namesStrategicLine)
    ) {
      return;
    }

    lastPayloadRef.current.payloadMain = payload;
    lastPayloadRef.current.strategicLine = namesStrategicLine;
    try {
      const response = await HttpClient.GET(API_PARAMETER_NAVBAR, payload);

      if (response.data.length > 0) {
        const mergedArray = [response?.data[0], ...namesStrategicLine, ...response?.data.slice(1)];
        const auxNavbar = mergedArray.map((item) => {
          return {
            ...item,
            to: item.link,
            id: item.id,
            onClick: () => handleNavigation(item.link, language),
            label: item.name ?? item.value
          };
        });

        setAuxNavBar(auxNavbar);
      }
    } catch (error) {
      console.error('Error fetching AuxNavBar:', error);
    }
  };

  // Fetch Main NavBar
  const fetchMainNavBar = async () => {
    // if (mainNavBarMobile.length > 0) return;
    const payload = {
      language: language.id,
      component: COMPONENTS?.NAVIGATION_BAR,
      main_parameter_code: parameters?.NAVBAR_MAIN_MOBILE?.code
    };

    if (JSON.stringify(lastPayloadRef.current.payloadAux) === JSON.stringify(payload)) {
      return;
    }

    lastPayloadRef.current.payloadAux = payload;

    try {
      const response = await HttpClient.GET(API_PARAMETER_NAVBAR, payload);

      if (response.data.length > 0) {
        const mainNavBarMobile = response.data.map((item) => ({
          ...item,
          to: '#',
          id: item.id,
          onClick: () => handleNavigation(item.link, language),
          label: item.value
        }));

        setMainNavBar(mainNavBarMobile);
      }
    } catch (error) {
      console.error('Error fetching MainNavBar:', error);
    }
  };

  // Fetch Strategic Line
  const fetchStrategicLineMobile = async () => {
    try {
      const response = await HttpClient.GET(API_STRATEGIC_LINE, {
        language: language.id,
        component: COMPONENTS.STRATEGIC_LINE
      });

      if (response.content.length > 0) {
        setStrategicsLines(response.content);
      }
    } catch (error) {
      console.error('Error fetching StrategicLine:', error);
    }
  };

  return { mainNavBarMobile, auxNavBarMobile, namesStrategicLine, fetchStrategicLineMobile };
};

export default useNavBarMobile;
